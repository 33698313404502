import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import HeroMiddleSection from "../components/dynamic-sections/HeroMiddleSection"
import SixBoxSection from "../components/dynamic-sections/SixBoxSection"

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import ValuesIcon from "../../assets/values.svg"
import ValuesNorhartIcon from "../../assets/value-norhart-logo.svg"
import ValuesRespectIcon from "../../assets/value-complete-respect.svg"
import ValuesResultsIcon from "../../assets/value-pursuit-results.svg"
import ValuesAttitudeIcon from "../../assets/value-attitude.svg"
import ValuesMindsetIcon from "../../assets/value-mindset.svg"
import ValuesReliableIcon from "../../assets/value-reliable.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const VisionPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/vision/",
          name: "Vision | Norhart",
          image: `${config.siteMetadata.siteUrl}/vision/norhart-vision-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Vision | Norhart"
      description="Norhart offers best-in-class benefits for our employees and their families. We invest in our employees to help them achieve greatness!"
      keywords="norhart, benefits, free benefits, employee benefits, health care, dental, vision, legal, maternity, paternity, childcare, forest lake, blaine, oakdale, minneapolis"
      imageTwitter={`${config.siteMetadata.siteUrl}/vision/norhart-vision-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/vision/norhart-vision-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title={"Our Vision"}
        subtitle="Our Strategies and Values"
        imageTitle="Norhart Vision"
        image="/vision/norhart-vision-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Norhart Vision"
        tagLine="We will disrupt and change our industry"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <SixBoxSection
        backgroundColorOne="#EFF2F4"
        backgroundColorTwo="#EFF2F4"
        boxIconFive={ValuesMindsetIcon}
        boxIconFour={ValuesAttitudeIcon}
        boxIconOne={ValuesNorhartIcon}
        boxIconSix={ValuesReliableIcon}
        boxIconThree={ValuesResultsIcon}
        boxIconTwo={ValuesRespectIcon}
        boxTitleOne="Norhart"
        boxSubTitleOne="This Is The Way"
        boxDescriptionOne="At Norhart, we hire, work, and live by our values. Our values are what defines and drives our culture at Norhart."
        boxTitleTwo="Value"
        boxSubTitleTwo="Achieve Great Things"
        boxDescriptionTwo="We lead from the front, love our work, we were born to do it; we accomplish far more than we thought possible."
        boxTitleThree="Value"
        boxSubTitleThree="Improve Every Day"
        boxDescriptionThree="We question the status quo;  We believe work shouldn't be complicated. We relentlessly simplify and automate our processes."
        boxTitleFour="Value"
        boxSubTitleFour="Be A Genuine Human"
        boxDescriptionFour="We are kind, supportive, and humble. We share credit, admit our mistakes and learn from them. We are open, honest, and vulnerable."
        boxTitleFive="Value"
        boxSubTitleFive="Resident Experience Obsession"
        boxDescriptionFive="We fight to make every resident touchpoint memorable and frictionless. We care about every little detail and its impact."
        boxTitleSix="Value"
        boxSubTitleSix="Level Yourself Up"
        boxDescriptionSix="We want to be the best in the world at what we do. We want to be so much better than the competition that the fight isn't fair."
        buttonText="#FFFFFF"
        colorPalette={colorPalette}
        icon={NorhartDarkCircleIcon}
        subtitle="We are a team of innovators with our values deeply embedded into our DNA! We love our work; we were born to do it! We dare to question the status quo! We obsess to make our resident experience magical! We want to be the best in the world at what we do!"
        title="Norhart Values"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Vision"
        title="Key Strategies"
        subTitle="We will achieve our ambitious goals by focusing on three key strategies: Love your work, delight residents, and disrupt the construction industry!"
        image="/labs/norhart-disrupt.gif"
        imageAlt="Norhart Industry Disruption"
        textColor={colorPalette.textColor}
      />

      <HeroMiddleSection
        imageTitle="Norhart Leadership"
        image="/vision/norhart-leadership-team.png"
        colorPalette={colorPalette}
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        titleTopLeft="Norhart Goals"
        title="Industry Domination"
        badgeTitle="We are shooting for the moon"
        subtitle="By 2032 we want to: Manage 192,000 units, design apartments at a lower cost than anyone else, and be the market leader in our industry!"
        imageTitle="Norhart Technology"
        image="/vision/norhart-industry-domination.png"
        colorPalette={colorPalette}
        colSizeOne={4}
        colSizeTwo={8}
        bottomMargin={0}
        asoEffect="fade-in"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="What Drives Us"
        subtitle="Our culture and values propel us forward. Always be respectful, results drive success, we love to cultivate innovation, and above all, we succeed together. We fail together. That's how we roll!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default VisionPage
